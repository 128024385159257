import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Footer } from '~/components'
import * as st from '~/assets/styl/PageDefault.module.styl'
import cn from 'classnames'

const PorqueUnicred = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <section className="container">
          <h1>O porquê da Unicred</h1>
          <p>
            O que significa trabalhar em uma instituição financeira Cooperativa
            Premium?
          </p>
        </section>
        <div className={st.imgWhy}>
          <section className={cn(st.round, 'container', 'clear-fix', st.green)}>
            <h2>Quando nos comparamos com os bancos</h2>
            <p>
              Queremos ser percebidos como uma cooperativa{' '}
              <strong>seletiva e qualificada</strong> que possui um{' '}
              <strong>atendimento encantador</strong>, caloroso e profissional.
            </p>
            <div>
              <p className={st.largeFont}>
                Se comparados aos bancos premium, queremos criar a percepção de
                que <strong>somos mais técnicos e muito mais preparados</strong>{' '}
                para propor soluções financeiras para nossos Cooperados.
              </p>
            </div>
          </section>
          <div className="container">
            <p>
              Da mesma forma, se comparados com outros sistemas cooperativistas,
              queremos construir o entendimento de que somos a alternativa mais
              <strong>bem preparada tecnicamente</strong> quando o assunto é
              gestão de investimentos, reforçando que{' '}
              <strong>somos a alternativa premium das cooperativas</strong>.
            </p>
          </div>
        </div>
        <div>
          <section
            className={cn(st.round, st.purple, 'container', 'clear-fix')}
          >
            <h2>Criar um mundo de prosperidade sem perdedores.</h2>
            <div>
              <p>
                É isso que <strong>nos move</strong>.
              </p>
              <p>
                É isso que <strong>nos motiva</strong> a “sair da cama de
                manhã”.
              </p>
              <p>
                É isso que <strong>nos faz atuar no setor bancário</strong>, um
                dos mais competitivos segmentos de mercado.
              </p>
              <p>
                É isso que <strong>nos alegra</strong> na competição com os
                bancos.
              </p>
              <p className={st.margin}>
                <strong>
                  É por isso que estamos todos aqui, todos os dias!
                </strong>
              </p>
            </div>
          </section>
        </div>
        <section className="container">
          <h2 className={st.paddingX}>
            É o propósito, o porquê da marca, que dá significado e sentido à
            organização
          </h2>
          <ul className={st.littleBox}>
            <li>
              <h3>Por quê</h3>
              <p>“Queremos criar um mundo de prosperidade sem perdedores.”</p>
            </li>
            <li>
              <h3>Como?</h3>
              <p>“Todos são donos, se respeitam e cooperam entre si.”</p>
            </li>
            <li>
              <h3>O que somos?</h3>
              <p>“Uma Instituição Financeira Cooperativa Premium.”</p>
            </li>
          </ul>
        </section>
      </main>
      <Footer location={location} />
    </>
  )
}

export default PorqueUnicred
